import { scrollAnimation } from './function/scroll-animation.js';

// ===========================================
// スムーススクロールの関数
// ===========================================
$(function () {
  $('a[href^="#"]').click(function () {
    const speed = 500,
        href = $(this).attr("href"),
        target = $(href == "#" || href == "" ? "html" : href),
        position = target.offset().top;

    $("html, body").animate(
      {
        scrollTop: position,
      },
      speed,
      "swing"
    );
    return false;
  });
});


// ===========================================
// スクロールアニメーションのオプション
// ===========================================
scrollAnimation({
  className: "c-fadeIn",
  triggerPoint: 100,
});


// ===========================================
// ヘッダーのスクロール時のインタラクション
// ===========================================
$(function () {
  let startPos = 0,
    winScrollTop = 0;

  $(window).on("load scroll", function () {
    winScrollTop = $(this).scrollTop();
    if (winScrollTop < startPos || winScrollTop <= 0) {
      $(".t-pageHeader").removeClass("t-pageHeader--hide");
    } else {
      $(".t-pageHeader").addClass("t-pageHeader--hide");
    }

    if (winScrollTop >= 200) {
      $(".t-pageHeader").addClass("t-pageHeader--back");
    } else {
      $(".t-pageHeader--back").removeClass("t-pageHeader--back");
    }

    startPos = winScrollTop;
  });
});


// ===========================================
// ハンバーガーメニュー展開時にスクロールを制限する
// ===========================================
$(function () {
  $("#t-hamburgerCheck").on("change", function () {
    setTimeout(function () {
      if ($("#t-hamburgerCheck").prop("checked") === true) {
        $("html").css({ overflow: "hidden" });
      } else {
        $("html").css({ overflow: "auto" });
      }
    }, 100);
  });

  let widthBefore = $(window).width();

  $(window).on("resize", function () {
    if (widthBefore !== $(window).width()) {
      $("html").css({ overflow: "auto" });
      $("#t-hamburgerCheck").prop("checked", false);
      widthBefore = $(window).width();
    }
  });
});


// ===========================================
// フッターのサブメニューのインタラクション
// ===========================================
$(function () {
  $('.t-footerNavSubList').each(function(){
    const subList = $(this),
          btn = subList.prev();

    $(window).on('resize load', function(){
      if( $(window).width() <= 640 ) {
        subList.css({ display: 'none' });
      } else {
        subList.css({ display: 'block' });
      }
      btn.removeClass('close');
    });

    btn.on('click', function(){
      if( $(window).width() <= 640 ) {
        btn.toggleClass('close');
        subList.slideToggle(300);
      }
    });
  });
});


// ===========================================
// ヘッダーのサブメニューのインタラクション
// ===========================================
$(function () {
  $('.t-headerSubNav').each(function(){
    const nav = $(this),
          switchBtn = nav.prev();
    let beforeWidth = $(window).width();

    switchBtn.on('click', function(){
      if( $(window).width() <= 1024 ) {
        switchBtn.toggleClass('close');
        nav.slideToggle(400);
      }
    });

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        switchBtn.removeClass('close');
        nav.attr('style', '');
      }
      beforeWidth = $(window).width();
    });
  });
});


// ===========================================
// 背景の円のインタラクション
// ===========================================
$(function () {
  function circleSet( area ) {
    area.each(function(){
      const area = $(this);
      let scroll,
          scslePoint;

      function circleSetCore( area ) {
        scroll = $(window).scrollTop();
        scslePoint = area.offset().top - ( $(window).height() * 2 / 3 );

        if(scroll >= scslePoint) {
          area.addClass('scale');
        }
      }

      circleSetCore( area );

      $(window).on('scroll', function(){
        circleSetCore( area );
      });
    });
  }

  circleSet( $('.c-circleArea') );
});


// ===========================================
// 事例一覧のレイアウト
// ===========================================
$(function () {
  $('.c-contentsList').each(function(){
    const ttl = $('.c-contentsList__ttl');
    let beforeWidth = $(window).width(),
        column;

    function tileSet() {
      if( $(window).width() > 1024 ) {
        column = 3;
      } else if( $(window).width() <= 1024 && $(window).width() > 640 ) {
        column = 2;
      } else if( $(window).width() <= 640 ) {
        column = 1;
      }

      ttl.tile( column );
    }
    tileSet();

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        ttl.css({ height: 'auto' });
        tileSet();
      }
      beforeWidth = $(window).width();
    });
  });
});


// ===========================================
// ページネーションのインタラクション
// ===========================================
$(function () {
  $('.wp-pagenaviWrapper').each(function(){
    const prevNextBtn = $(this).find('.previouspostslink, .nextpostslink'),
          btn = $(this).find('a.page');

    prevNextBtn.html(
      '<span class="c-transitionBtn__icon">' +
        '<svg xmlns="http://www.w3.org/2000/svg" version="1.1">' +
          '<linearGradient id="gradation">' +
            '<stop offset="0%" stop-color="#ffb952" />' +
            '<stop offset="100%" stop-color="#cc0033" />' +
          '</linearGradient>' +
          '<circle class="circle" stroke="url(#gradation)">' +
        '</svg>' +

        '<svg xmlns="http://www.w3.org/2000/svg" version="1.1">' +
          '<circle class="circle circle--gray">' +
        '</svg>' +

        '<svg xmlns="http://www.w3.org/2000/svg" version="1.1">' +
          '<circle class="circle circle--move" stroke="url(#gradation)">' +
        '</svg>' +

        '<span class="arrow"></span>' +
      '</span>'
    )

    btn.each(function(){
      $(this).html(
        '<span>' + $(this).html() + '</span>' +
        '<span>' + $(this).html() + '</span>'
      )
    });
  });
});


// ===========================================
// 記事本文のテーブルのデザイン
// ===========================================
$(function () {
  $('.c-articleBody table tr').each(function(){
    const head = $(this);

    if( ! head.find('td').length ) {
      head.addClass('gradation');
    }
  });
});


// ===========================================
// 記事本文のテーブルのデザイン
// ===========================================
$(function () {
  $('.c-mobileSliderWrapper').each(function(){
    const slide = $(this).find('.c-contentsList--mobileSlider'),
          nav = $(this).find('.relatecNav'),
          gauge = $(this).find('.relatecGauge');
    let beforeWidth = $(window).width(),
        slickActive = false;

    function slideSet( slide, nav ) {
      if( $(window).width() <= 1024 ) {
        slide.slick({
          slidesToShow: 1,
          appendArrows: nav,
          dots: true,
          infinite: false,
        });

        slickActive = true;
      } else {
        slickActive = false;
      }

      gaugeSet( slide, gauge );

      slide.on('swipe', function(){
        gaugeSet( slide, gauge );
      });

      nav.find('.slick-arrow').on('click', function(){
        gaugeSet( slide, gauge );
      });
    }

    function gaugeSet( slide, gauge ) {
      if( slide.find('.slick-dots li').length ) {
        slide.find('.slick-dots li').each(function(){
          const item = $(this);
          let gaugeValue;

          if( item.hasClass('slick-active') ) {
            gaugeValue = Number( item.find('button').html() )  / slide.find('.slick-dots li').length * 100;
          }

          gauge.find('span').css({ width: gaugeValue + '%' });
        });
      }
    }

    slideSet( slide, nav );

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        if( slickActive === true ) {
          slide.slick('unslick');
        }
        slideSet( slide, nav );
      }

      beforeWidth = $(window).width();
    });
  });
});


// ===========================================
// 記事本文のテキストリンクのインタラクション
// ===========================================
$(function () {
  $('.c-articleBody p > a').each(function(){
    $(this).attr( 'data-txt', $(this).html() );
  });
});


// ===========================================
// モーダルのインタラクション
// ===========================================
$(function () {
  $('.c-modalTrigger').each(function(){
    const trigger = $(this),
          modal = $('.c-modal[data-modal="' + trigger.attr('data-modal') + '"]'),
          shadow = modal.find('.c-modalShadow'),
          close = modal.find('.c-modalClose');

    trigger.on('click', function(){
      modal.addClass('on');
      $('html').css({ overflow: 'hidden' });
    });

    shadow.on('click', function(){
      modal.removeClass('on');
      $('html').css({ overflow: 'auto' });
    });

    $(window).on('resize', function(){
      modal.removeClass('on');
      $('html').css({ overflow: 'auto' });
    });

    close.on('click', function(){
      shadow.click();
    });
  });
});


// ===========================================
// シェアボタンのリンクボタンのインタラクション
// ===========================================
$(function () {
  $('.c-articleShare__icon--link').each(function(){
    const btn = $(this);

    btn.on('click', function(){
      btn.addClass('c-articleShare__icon--link--on');

      setTimeout(function() {
        btn.removeClass('c-articleShare__icon--link--on');
      }, 800);
    });
  });
});