// ===========================================
// スクロールアニメーションの関数
// ===========================================
export const scrollAnimation = (data) => {
  function scrollAnimationBase(data) {
    let scroll = $(window).scrollTop(),
        windowHeight = $(window).height();
    $("." + data.className).each(function () {
      const _this = $(this);
      let position = _this.offset().top,
          minusValue = windowHeight - data.triggerPoint;

      if (scroll > position - minusValue) {
        _this.addClass(data.className + "--active");
      }
    });
  }

  $(window).on("load scroll", function () {
    scrollAnimationBase(data);
  });
};