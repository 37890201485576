import { scrollAnimation } from './function/scroll-animation.js';
const tile = require('./function/tile.js');
const slick = require('/assets/js/slick/slick.js');
import '/assets/js/common.js';
import '/assets/css/slick/slick.css';
import '/assets/css/_common.scss';
import '/assets/css/top.scss';

// スクロールアニメーションのオプション
scrollAnimation({
  className: "newsArea",
  triggerPoint: 400
});

let getTriggerPoint;

if( $(window).width() <= 640 ) {
  getTriggerPoint = 100;
} else {
  getTriggerPoint = 200;
}

scrollAnimation({
  className: "aboutImgArea",
  triggerPoint: getTriggerPoint
});

scrollAnimation({
  className: "txtSlideWrapper--about",
  triggerPoint: 100
});

scrollAnimation({
  className: "serviceArea",
  triggerPoint: 100
});


// ===========================================
// メインビジュアルのインタラクション
// ===========================================
$(function () {
  $('.mVslide').each(function(){
    const slide = $(this);
    let tmb,
        beforeWidth = $(window).width(),
        slideInnerWidth = 0,
        duration = 0;

    if( slide.find('.mVslide__item').length <= 1 ) {
      slide.find('.mVslideInner').append( slide.find('.mVslideInner').html() );
      slide.next().append( slide.next().html() );
    }

    slide.find('.mVslideInner').append( slide.find('.mVslideInner').html() );
    slide.next().append( slide.next().html() );

    const item = slide.find('.mVslide__item'),
          bgItem = slide.next().find('.mvBg__item');

    item.find('.mVslide__tmb').each(function( n ){
      $(this).attr('data-target', Number(n+1));
    });

    bgItem.each(function( n ){
      $(this).attr('data-target', Number(n+1));
      $(this).find('video').attr('id', 'movie' + Number(n+1));
    });

    function slideSet( slide, item ) {
      slide.addClass('pEventsNone');

      item.each(function(){
        slideInnerWidth = slideInnerWidth + $(this).outerWidth();
        duration = duration + 16;
      });

      $('.slideStyle').html(
        '@keyframes slide {' +
          '0% { transform: translateX(0px); }' +
          '100% { transform: translateX(' + -1 * ( slideInnerWidth / 2 ) + 'px); }' +
        '}' +
        '.mVslideInner { animation: slide ' + duration / 2 + 's linear 0s infinite; }' +
        '.mVslide.onBg .mVslideInner { animation-play-state: paused; }' +
        '@media screen and (max-width: 1024px) {' +
          '.mVslide.onBg .mVslideInner { animation-play-state: running; }' +
        '}'
      );

      tmb = slide.find('.mVslide__tmb');

      setTimeout(function () {
        slide.removeClass('pEventsNone');
      }, 1000);

      tmb.hover(
        function (){
          const targetNum = $(this).attr('data-target'),
                bgItem = $('.mvBg__item[data-target="' + targetNum + '"]');

          if( $(window).width() > 1024 ) {
            slide.addClass('onBg');
            bgItem.addClass('on');
          }

          if( document.getElementById( 'movie' + targetNum ) ) {
            const movie = document.getElementById( 'movie' + targetNum );

            movie.load();
            setTimeout(function () {
              movie.play();
            }, 300);
          }
        },
        function () {
          const targetNum = $(this).attr('data-target'),
                bgItem = $('.mvBg__item[data-target="' + targetNum + '"]');

          if( $(window).width() > 1024 ) {
            slide.removeClass('onBg');
            bgItem.removeClass('on');
          }

          if( document.getElementById( 'movie' + targetNum ) ) {
            const movie = document.getElementById( 'movie' + targetNum );

            movie.pause();
          }

          if(
            ! slide.hasClass('pEventsNone') &&
            $(window).width() > 1024
          ) {
            slide.addClass('pEventsNone');

            setTimeout(function () {
              slide.removeClass('pEventsNone');
            }, 400);
          }
        }
      );
    }

    slideSet( slide, item );

    $(window).on('resize', function(){
      if( $(window).width() !== beforeWidth ) {
        slideInnerWidth = 0;
        duration = 0;
        slideSet( slide, item );
      }
      beforeWidth = $(window).width();
    });
  });

  $('.mVSlideTxt').each(function(){
    const slideTxt = $(this),
          item = slideTxt.find('.mVSlideTxt__item');
    let time = 0,
        beforeWidth = $(window).width();

    if( item.length < 2 ) {
      slideTxt.append( slideTxt.html() );
    }

    function slideTxtSet( slideTxt ) {
      slideTxt.css({ width: item.width() + 'px' });
      time = Math.floor( slideTxt.width() * 9 );

      slideTxt.slick({
        autoplay: true,
        autoplaySpeed: 0,
        arrows: false,
        slidesToShow: 1,
        cssEase: 'linear',
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: time,
      });
    }

    slideTxtSet( slideTxt );

    $(window).on('resize', function(){
      if( $(window).width() !== beforeWidth ) {
        slideTxt.slick('unslick');
        slideTxtSet( slideTxt );
      }
      beforeWidth = $(window).width()
    });
  });
});


// ===========================================
// テキストスライド
// ===========================================
$(function () {
  $('.txtSlide').each(function(){
    const txtSlide = $(this);
    let slideActive = false,
        beforeWidth = $(window).width();

    txtSlide.append( txtSlide.html() );

    function txtSlideSet() {
      txtSlide.slick({
        autoplay: true,
        autoplaySpeed: 0,
        arrows: false,
        slidesToShow: 1,
        cssEase: 'linear',
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 20000,
      });
    }

    txtSlideSet();

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        txtSlide.slick('unslick');
        txtSlideSet();
      }
      beforeWidth = $(window).width();
    });
  });
});


// ===========================================
// イベントスケジュールのスライドショー
// ===========================================
$(function () {
  $('.newsAreaSlideWrapper').each(function(){
    const wrapper = $(this),
          slide = wrapper.find('.newsAreaSlide'),
          nav = wrapper.find('.newsSlideNav'),
          gauge = wrapper.find('.newsSlideGauge');
    let contentsWidth,
        num,
        slideStatus = false,
        beforeWidth = $(window).width(),
        gaugeValue;

    function slideSet( wrapper, slide, nav ) {
      contentsWidth = wrapper.width();

      if( $(window).width() > 1024 ) {
        num = Math.floor( contentsWidth / 400 );
        if( num <= 2 ) {
          num = 2;
        }
      } else if( $(window).width() <= 1024 && $(window).width() > 640 ) {
        num = 2;
      } else {
        num = 1;
      }

      if( num < slide.find('.newsAreaSlide__item').length ) {
        slideStatus = true;
        slide.removeClass('unslick');
        slide.find('.newsAreaSlide__item > a').css({ height: 'auto' });
        slide.find('.newsAreaSlide__item .newsAreaSlide__icon').css({ height: 'auto' });

        slide.slick({
          appendArrows: nav,
          slidesToShow: num,
          dots: true,
          infinite: false,
        });

        slide.find('.newsAreaSlide__item > a').tile();
        slide.find('.newsAreaSlide__item .newsAreaSlide__icon').tile();
      } else {
        slideStatus = false;
        slide.addClass('unslick');
        slide.find('.newsAreaSlide__item > a').css({ height: 'auto' });
        slide.find('.newsAreaSlide__item > a').tile();
        slide.find('.newsAreaSlide__item .newsAreaSlide__icon').tile();
      }

      gaugeSet();

      nav.find('.slick-arrow').on('click', function(){
        gaugeSet();
      });
    }

    function gaugeSet() {
      if( $('.slick-dots li').length ) {
        $('.slick-dots li').each(function(){
          const item = $(this);

          if( item.hasClass('slick-active') ) {
            gaugeValue = Number( item.find('button').html() )  / $('.slick-dots li').length * 100;
          }

          gauge.find('span').css({ width: gaugeValue + '%' });
        });
      }
    }

    slideSet( wrapper, slide, nav );

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        if( slideStatus === true ) {
          slide.slick('unslick');
        }
        slideSet( wrapper, slide, nav );
      }

      beforeWidth = $(window).width();
    });

    gaugeSet();

    slide.on('swipe', function(){
      gaugeSet();
    });
  });
});


// ===========================================
// ニュースエリアのリストのレイアウト
// ===========================================
$(function () {
  let maxHeight = 0,
      beforeWidth = $(window).width();

  function setheight(wrapper, maxHeight) {
    wrapper.find('.c-newsList').each(function(){
      const _this = $(this);

      if( _this.height() > maxHeight ) {
        maxHeight = _this.height();
      }
    });

    wrapper.height( maxHeight );
  }

  $('.newsListWrapper').each(function(){
    const wrapper = $(this);

    setheight(wrapper, maxHeight);

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        setheight(wrapper, maxHeight);
      }
      beforeWidth = $(window).width();
    });
  });
});